$(() => {


    // GLOBALS
    ////////////////////////////////
    let $body = $('body')
    let $indexCont = $('nav .index_container')
    let $slideCont = $('main .slider_container')
    let $projectCont = $('main .slider_container .project_container')


    // MEASUREMENTS
    ////////////////////////////////

    // Window height
    let $vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${$vh}px`);

    // Window width
    let $vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${$vw}px`);

    // Animation speeds
    let aniIndexCont = $indexCont.css('transition-duration').replace('s', '') * 1000;

    // Update on resize
    window.addEventListener('resize', () => {
        $vh = window.innerHeight * 0.01;
        $vw = window.innerWidth * 0.01;

        document.documentElement.style.setProperty('--vh', `${$vh}px`);
        document.documentElement.style.setProperty('--vw', `${$vw}px`);
    });

    let zoom = 0
    $(window).on('touchstart', function () {
        setTimeout(function () {
            console.log('touch')
            let viewportScale = document.documentElement.clientWidth / window.innerWidth
            if (viewportScale > 1) {
                zoom = 1
            } else {
                zoom = 0
            }
            console.log(zoom)
        }, 100)
    })


    // INITIATIONS
    ////////////////////////////////

    // Index container
    $indexCont.css('display', 'none')
    $indexCont.addClass('closed')
    $indexCont.removeClass('init')

    // Projects
    $projectCont.first().addClass('active')
    $projectCont.first().next().addClass('next')
    $projectCont.last().addClass('prev')

    projReset()
    $slideCont.removeClass('hide')


    // Links
    $('a').each(function () {
        $(this).attr('target', '_blank')
    })


    // TRIGGERS
    ////////////////////////////////

    // Key presses
    $(document).on('keydown', function (event) {
        // Escape
        if (event.which === 27) {
            if ($indexCont.is('.open')) {
                closeIndex()
            }
            if ($('.project_container .project_text.open').length) {
                closeDetails()
            }
        }
        // Left
        if (event.which === 37) {
            navLeft()
        }
        // Right
        if (event.which === 39) {
            navRight()
        }
        // Up
        if (event.which === 38) {
            navUp()
        }
        // Down
        if (event.which === 40) {
            navDown()
        }
        // (I)
        if (event.which === 73) {
            toggleDetails()
        }
        // (O)
        if (event.which === 79) {
            toggleIndex()
        }
    });

    // Scroll motions
    let i = 0;
    $(window).on('mousewheelintention', function (event) {
        if (zoom === 0) {
            let mouseIntent = event.certainty
            if (mouseIntent === 1) {
                let direction = (function () {
                    let delta = (event.type === 'DOMMouseScroll' ? event.originalEvent.detail * -40 : event.originalEvent.wheelDelta);
                    return delta > 0 ? 0 : 1;
                }());

                if (i === 0) {
                    // Scroll down
                    if (direction === 1) {
                        navDown()
                    }

                    // Scroll up
                    if (direction === 0) {
                        navUp()
                    }

                    i++;
                }
                clearTimeout($.data(this, 'scrollTimer'));
                $.data(this, 'scrollTimer', setTimeout(function () {
                    i = 0;
                }, 100));
            }
        }
    });

    // Cursor position
    $(document).on("mousemove", function (event) {
        let viewportX = window.innerWidth * 0.5
        let positionX = event.pageX

        if (positionX > viewportX) {
            $body.addClass('right')
            $body.removeClass('left')
        } else {
            $body.addClass('left')
            $body.removeClass('right')
        }

    });

    // Swipe motions
    let touchstartX = 0
    let touchendX = 0
    let touchstartY = 0
    let touchendY = 0

    document.addEventListener('touchstart', e => {
        touchstartX = e.changedTouches[0].screenX
        touchstartY = e.changedTouches[0].screenY
    })

    document.addEventListener('touchend', e => {
        touchendX = e.changedTouches[0].screenX
        touchendY = e.changedTouches[0].screenY
        if (zoom === 0) {
            checkDirection()
        }
    })

    function checkDirection() {
        let distanceX = Math.abs(touchendX - touchstartX)
        let distanceY = Math.abs(touchendY - touchstartY)

        if (distanceX > distanceY) {
            // Swipe left
            if (touchendX < touchstartX) {
                navRight()
            }
            // Swipe right
            if (touchendX > touchstartX) {
                navLeft()
            }
        } else {
            // Swipe up
            if (touchendY < touchstartY) {
                navDown()
            }
            // Swipe down
            if (touchendY > touchstartY) {
                navUp()

                if ($('main .slider_container .project_container.active .project_text').is('.open')) {
                    toggleDetails()
                }
            }
        }
    }

    // Click events
    $body.on('click', function (event) {
        let $target = $(event.target)

        if ((!$target.is('a')) && (!$target.hasClass('a'))) {
            if ($indexCont.is('.open')) {
                closeIndex()
            }
            if (($('.project_container .project_text.open').length) && (!$target.parents().hasClass('project_header'))) {
                closeDetails()
            }
        }
    })

    $body.on('click', 'main .slider_container .project_container.active', function () {
        if (zoom === 0) {
            if ($body.is('.right')) {
                navRight()
            } else {
                navLeft()
            }
        }
    })

    $body.on('click', 'nav .home_btn', function () {
        toggleIndex()
    })

    $body.on('click', 'main .project_header', function () {
        toggleDetails()
    })


// FUNCTIONS
////////////////////////////////

    function toggleIndex() {
        if ($indexCont.is('.open')) {
            closeIndex()
        } else {
            closeDetails()
            openIndex()
        }
    }

    function openIndex() {
        $indexCont.css('display', 'block')
        $slideCont.addClass('hide')
        $slideCont.addClass('blur')
        setTimeout(function () {
            $indexCont.removeClass('closed')
            $indexCont.addClass('open')
        }, 1)
    }

    function closeIndex() {
        $indexCont.removeClass('open')
        $indexCont.addClass('closed')
        $slideCont.removeClass('hide')
        $slideCont.removeClass('blur')
        setTimeout(function () {
            $indexCont.css('display', 'none')
        }, (aniIndexCont))
    }

    function toggleDetails() {
        if (!$('main .slider_container .project_container.active .project_text').is('.empty')) {
            if (($('.project_container .project_text.open').length)) {
                closeDetails()
            } else {
                openDetails()
            }
            checkDetails()
        }
    }

    function checkDetails() {
        if ($('main .project_container.active img').first().is('.active')) {
            $slideCont.removeClass('hide')
        } else {
            $slideCont.addClass('hide')
        }
    }

    function openDetails() {
        let $projectDetails = $('.project_container.active .project_text .details');
        let $height = $projectDetails[0].scrollHeight
        closeIndex()
        $slideCont.addClass('hide')
        $slideCont.addClass('blur')
        $('.project_container.active .project_text').addClass('open')

        $projectDetails.css('max-height', $height)
        setTimeout(function () {
            $projectDetails.css('max-height', 'auto')
        }, aniIndexCont)
    }

    function closeDetails() {
        let $projectDetails = $('.project_container.active .project_text .details');

        $slideCont.removeClass('hide')
        $slideCont.removeClass('blur')
        $('.project_container .project_text.open').removeClass('open')
        $projectDetails.css('max-height', '')
    }

    function navLeft() {
        let $active = $('main .project_container.active .active');
        let $next = $('main .project_container.active .next');
        let $prev = $('main .project_container.active .prev');

        if (!$slideCont.is('.blur')) {
            if ($active.prev().length === 0) {
                projPrev()
            } else {
                $prev.removeClass('prev')
                $active.removeClass('active')
                $next.removeClass('next')

                $prev.addClass('active')
                $active.addClass('next')

                if ($prev.prev().length === 0) {
                    $('main .project_container.active img').last().addClass('prev')
                } else {
                    $prev.prev().addClass('prev')
                }

                $next.css('transition-duration', '0s')
                setTimeout(function () {
                    $next.css('transition-duration', '')
                }, aniIndexCont)

                checkDetails()
            }
        }


    }

    function navRight() {
        let $active = $('main .project_container.active .active');
        let $next = $('main .project_container.active .next');
        let $prev = $('main .project_container.active .prev');

        if (!$slideCont.is('.blur')) {
            if ($active.next().is('.project_text')) {
                projNext()
            } else {
                $prev.removeClass('prev')
                $active.removeClass('active')
                $next.removeClass('next')

                $next.addClass('active')
                $active.addClass('prev')

                if ($next.next().is('.project_text')) {
                    $('main .project_container.active img').first().addClass('next')
                } else {
                    $next.next().addClass('next')
                }

                $prev.css('transition-duration', '0s')
                setTimeout(function () {
                    $prev.css('transition-duration', '')
                }, aniIndexCont)

                checkDetails()
            }
        }
    }

    function navUp() {
        if (!$slideCont.is('.blur')) {
            projPrev()
        }
    }

    function navDown() {
        if (!$slideCont.is('.blur')) {
            projNext()
        }
    }

    function projNext() {
        let $active = $('main .project_container.active')
        let $next = $('main .project_container.next')
        let $prev = $('main .project_container.prev')

        if (!$('main .project_container.active img').first().is('.active')) {
            $active.addClass('trans')
        }

        $active.removeClass('active')
        $next.removeClass('next')
        $prev.removeClass('prev')

        $active.addClass('prev')
        $next.addClass('active')

        if ($next.next().length) {
            $next.next().addClass('next')
        } else {
            $('main .project_container').first().addClass('next')
        }

        $slideCont.addClass('hide')
        projReset()

        setTimeout(function () {
            $active.removeClass('trans')
        }, aniIndexCont)
    }

    function projPrev() {
        let $active = $('main .project_container.active')
        let $next = $('main .project_container.next')
        let $prev = $('main .project_container.prev')

        if (!$('main .project_container.active img').first().is('.active')) {
            $active.addClass('trans')
        }

        $active.removeClass('active')
        $next.removeClass('next')
        $prev.removeClass('prev')

        $prev.addClass('active')
        $active.addClass('next')

        if ($prev.prev().length) {
            $prev.prev().addClass('prev')
        } else {
            $('main .project_container').last().addClass('prev')
        }

        $slideCont.addClass('hide')
        projReset()

        setTimeout(function () {
            $active.removeClass('trans')
        }, aniIndexCont)
    }

    function projReset() {
        let $nextText = $('main .slider_container .project_container.next .project_text');
        if (!$('main .project_container.next img').first().is('.active')) {
            $nextText.css('opacity', 0)
        }
        setTimeout(function () {
            $('main .project_container img').each(function () {
                $(this).removeClass('prev')
                $(this).removeClass('active')
                $(this).removeClass('next')
            })
            $projectCont.each(function () {
                $(this).children('img').first().addClass('active')
                $(this).children('img').first().next().addClass('next')
                $(this).children('img').last().addClass('prev')
            })
            $nextText.css('opacity', '')
        }, aniIndexCont * 2)

        checkDetails()
    }


})

